<template>
    <default-layout>
        <!-- <header-image/> -->
        <div class="container-fluid px-3 px-md-5 visitas-main">

            <div class="spainhn-solid-red-background d-flex py-3" style="height:auto; min-height:75px; width:100%">
                <p class="my-auto ps-4 spainhn-subtitle-2 text-white">{{ $t("default.MENSAJE_SERVICIOS_BODA_1") }}</p>
            </div>
            <div class="text-center my-4 spainhn-subtitle-3">
                <p>{{ $t("default.MENSAJE_SERVICIOS_BODA_2") }}</p>
            </div>

            <div class="d-flex mb-5">
                <b-select class="mt-2 spainhn-custom-input me-3 w-100" @change="filterByComunidad" v-model="localizacionFilter" :options="comunidadesAutonomas"></b-select>
                <b-select class="mt-2 spainhn-custom-input mx-1 w-100 overflow-hidden" @change="filterByRutas" v-model="rutaFilter" :options="rutasOptionsFiltered"></b-select>
                <b-select class="mt-2 spainhn-custom-input ms-3 w-100" @change="filterByMonument" v-model="castilloFilter" :options="monumentosFiltered"></b-select>
            </div>

            <b-spinner class="d-flex mx-auto" variant="danger" v-bind:class="{'d-none':!loading}"></b-spinner>

            <div class="d-flex mb-5">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards-2 v-for="(edificio, index) in edificiosFiltered" v-bind:key="index"
                        :monument="edificio.webName"
                        :provincia="edificio.provincia"
                        :municipio="edificio.municipio"
                        :imageUrl="`${imagenesUrl}/monumentos/${edificio.oldId}/${edificio.imagen}`"
                        :text="edificio.webDescription"
                        :id="edificio.id"
                        :nombreUrl="edificio.nombreNormalizado"
                        extendUrl="#services"
                        role="button"/>
                </div>
            </div>

            <div class="d-flex mb-5">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards-2 v-for="(edificio, index) in edificiosFilteredWithFilter" v-bind:key="index"
                        :monument="edificio.webName"
                        :provincia="edificio.provincia"
                        :municipio="edificio.municipio"
                        :imageUrl = "`${imagenesUrl}/monumentos/${edificio.oldId}/${edificio.imagen}`"
                        :text="edificio.webDescription"
                        :id="edificio.id"
                        :nombreUrl="edificio.nombreNormalizado"
                        extendUrl="#services"
                        role="button"/>
                </div>
            </div>    


        </div>
    </default-layout>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import DefaultLayout from '../../layouts/DefaultLayout.vue'

import Cards2 from '../../components/Cards2.vue'

import ApiService from '../../services/ApiService';

export default {
    components:{
        DefaultLayout, 
        Cards2
    },
    data(){
        return{
            edificios:[],
            edificiosFiltered:[],
            monumentos:[],
            castilloFilter: null,
            edificiosFilteredWithFilter:[],

            selectedType: "Castillos",
            localizacionFilter: null,
            serviciosFilter: null,
            rutaFilter: null,
            activeColor:'rgba(242,90,85,1)',
            disabledColor:'rgba(225,225,225,1)',
            // Con esta variable determinamos el status del icono
            castillosColor:'rgba(225,225,225,1)',
            palaciosColor:'rgba(225,225,225,1)',
            palaciosRealesColor:'rgba(225,225,225,1)',
            recintosColor:'rgba(225,225,225,1)',
            arteColor:'rgba(225,225,225,1)',
            monasteriosColor:'rgba(225,225,225,1)',
            localizacionOptions:[
                {'value':null, 'text':"Búsqueda por localización"}
            ],
            rutasOptions:[
                // {'value':null, 'text':this.$t('default.SELECT_RUTA')}
            ],            
            serviciosOptions:[
                {'value':null, 'text':this.$t( 'default.SELECT_SERVICE') }
            ],
            descripciones: this.$t('default.castillosDescriptionText'),
            rand:0,
            imagenesUrl: null,
            loading: true,

            comunidadesAutonomas: [],
            rutasOptionsFiltered: [],
            monumentosFiltered: []
        }
    },
    methods:{
        showContent(){
            // console.log('click en castillo');
        },
        filterByTipo(tipo){
            let $vm = this;
            console.log(tipo);
            if(tipo == 'Monasterios'){
                console.log("Monasterios")
                $vm.edificiosFiltered = [];
            }
            else if(tipo == ""){
                $vm.edificiosFiltered = $vm.edificios;
                let tempResult = $vm.edificiosFiltered.sort((a,b) => 0.5 - Math.random());
                $vm.edificiosFiltered = [...tempResult]
            }
            else{
                $vm.edificiosFiltered = $vm.edificios.filter( edificio => {
                // console.log(edificio);
                    return edificio.tipoDeMonumento == tipo;
                })
                let tempResult = $vm.edificiosFiltered.sort((a,b) => 0.5 - Math.random());
                $vm.edificiosFiltered = [...tempResult]
            }

            $vm.rand += 1;

        },
        filterByComunidad(){
            let $vm = this;            
            if($vm.localizacionFilter != null){                
                $vm.rutaFilter = null
                $vm.castilloFilter = null
                let monumentosTemp2 = $vm.monumentos.filter( monumento =>{
                    return monumento.comunidad ==  $vm.localizacionFilter
                })

                $vm.monumentosFiltered = monumentosTemp2.map( monumento =>{
                    //value y text (dependiendo del idioma)
                    let nombreEdificio = monumento.webName;
                    return {value: monumento, text: nombreEdificio}                    
                })
                // $vm.monumentosFiltered = monumentosTemp2.map( monumento =>{
                //     //value y text (dependiendo del idioma)
                //     let nombreEdificio;
                //     if(this.$i18n.locale == 'es'){
                //         nombreEdificio = monumento.nombres[0].texto;
                //     }
                //     if(this.$i18n.locale == 'en'){
                //         nombreEdificio = monumento.nombres[1].texto;
                //     }
                //     if(this.$i18n.locale == 'fr'){
                //         nombreEdificio = monumento.nombres[2].texto;
                //     }
                //     if(this.$i18n.locale == 'de'){
                //         nombreEdificio = monumento.nombres[3].texto;
                //     }

                //     return {value: monumento, text: nombreEdificio}                    
                // })
                $vm.monumentosFiltered.unshift({value: null, text: this.$t('default.ALL_MONUMENTS')})

                let monumentosIdsArray = monumentosTemp2.map( monumento => {
                    return monumento._id;
                } )

                $vm.rutasOptionsFiltered = $vm.rutasOptions.filter(ruta =>{
                    if(ruta.value == null){
                        return true
                    }else{
                        var castillosInRuta = ruta.value.castillos.map( rutaValue2 => {
                            return rutaValue2._id;
                        })

                        // console.log(castillosInRuta)
                        let rutaValida = false;
                        monumentosIdsArray.forEach( monumentoId => {
                            // console.log(monumentoId)
                            if(castillosInRuta.includes(monumentoId)){                                
                                rutaValida = true;
                                return true;
                            }
                        })

                        return rutaValida;                        

                    }
                })
                $vm.selectedType = "";
                this.edificiosFiltered = [];

                let monumentos = $vm.edificios
                //Procedemos a filtrar y pintar las cards de los monumentos
                let edificiotTem = monumentos.filter(edificio =>{
                    let monumentoValido = false
                    $vm.monumentosFiltered.forEach(monumento =>{
                        
                        if(monumento.value != null){

                            if(edificio.id == monumento.value._id){
                                monumentoValido = true
                            }
                        }

                    
                    })
                    return monumentoValido;
                })
                this.edificiosFiltered = null;
                this.edificiosFilteredWithFilter = edificiotTem;

            }

            else{
                this.edificiosFiltered = $vm.edificios;
                this.edificiosFilteredWithFilter = [];
            }

            // this.edificiosFiltered = [];
            // let monumentos = $vm.edificios
            // //Procedemos a filtrar y pintar las cards de los monumentos
            // let edificiotTem = monumentos.filter(edificio =>{
            //     let monumentoValido = false
            //     $vm.monumentosFiltered.forEach(monumento =>{
                    
            //         if(monumento.value != null){

            //             if(edificio.id == monumento.value._id){
            //                 monumentoValido = true
            //             }
            //         }

                
            //     })
            //     return monumentoValido;
            // })
            // this.edificiosFiltered = null;
            // this.edificiosFilteredWithFilter = edificiotTem;
            
        },
        filterByRutas(){
            let $vm = this

            if( $vm.rutaFilter ){
                let castillosIds = $vm.rutaFilter.castillos.map(ruta => {return ruta._id})
                let monumentos = $vm.monumentos.filter( monumento => {
                    if(castillosIds.includes(monumento._id)){
                        return true;
                    }
                })
                $vm.monumentosFiltered = monumentos.map(monumento =>{
                    let nombreEdificio = monumento.webName;
                    return {value: monumento, text: nombreEdificio}                            
                })   

                $vm.monumentosFiltered.unshift({value: null, text: this.$t('default.ALL_MONUMENTS') })
          

                $vm.monumentosFiltered = monumentos.map(monumento =>{
                    let nombreEdificio;
                    if(this.$i18n.locale == 'es'){
                        nombreEdificio = monumento.nombres[0].texto;
                    }
                    if(this.$i18n.locale == 'en'){
                        nombreEdificio = monumento.nombres[1].texto;
                    }
                    if(this.$i18n.locale == 'fr'){
                        nombreEdificio = monumento.nombres[2].texto;
                    }
                    if(this.$i18n.locale == 'de'){
                        nombreEdificio = monumento.nombres[3].texto;
                    }
                    return {value: monumento, text: nombreEdificio}                            
                })
                $vm.monumentosFiltered.unshift({value: null, text: this.$t('default.ALL_MONUMENTS') })

                this.edificiosFiltered = [];
                //Procedemos a filtrar y pintar las cards de los monumentos
                let edificiotTem = $vm.edificios.filter(edificio =>{
                    let monumentoValido = false
                    $vm.monumentosFiltered.forEach(monumento =>{
                        
                        if(monumento.value != null){

                            if(edificio.id == monumento.value._id){
                                monumentoValido = true
                            }
                        }

                    
                    })
                    return monumentoValido;
                })
                this.edificiosFiltered = null;
                this.edificiosFilteredWithFilter = edificiotTem;
            }
            else{
                this.edificiosFiltered = $vm.edificios;
                this.edificiosFilteredWithFilter = [];

                this.filterByComunidad();
            }
        },
        filterByMonument(){
            let $vm = this
            if($vm.castilloFilter != null){

                $vm.edificiosFiltered = null;
                $vm.edificiosFilteredWithFilter = $vm.edificios.filter(edificio =>{
                    if(edificio.id == $vm.castilloFilter._id){
                           return true;
                        }
                })
            }
            else{
                this.edificiosFiltered = $vm.edificios;
                this.edificiosFilteredWithFilter = [];
            
                this.filterByRutas();
            }
        }

    },
    setup() {
        
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    created(){
        let $vm = this;
        $vm.$i18n.locale = $vm.$store.getters.getIdioma;

        if($vm.$i18n.locale == null){
            $vm.$i18n.locale = 'es';
        }

        
        const _language = this.$store.getters.getIdioma ?? "es";

        let servicio = "bodas"
        $vm.loading = true;
        ApiService.getEdificacionByServicio(servicio, _language)
            .then( result => {
                $vm.loading = false;
                // console.log(result);
                $vm.edificios = [...result.data];   
                // console.log(result);
                $vm.filterByTipo("");
            })
            .catch(err => {
                $vm.loading = false;
                console.log(err);
            })

        ApiService.getAllRutas(_language).then( res =>{
            let data = res.data;
            //this.rutasOptions = [...res.data]
            data.forEach(ruta =>{
                let nombreRuta = ruta.webName;
                this.rutasOptions.push({value:ruta, text: nombreRuta})
                           
            //      if(this.$i18n.locale == 'es'){
            //         nombreRuta = ruta.nombres[0].texto;
            //     }
            //     if(this.$i18n.locale == 'en'){
            //         nombreRuta = ruta.nombres[1].texto;
            //     }
            //     if(this.$i18n.locale == 'fr'){
            //         nombreRuta = ruta.nombres[2].texto;
            //     }
            //     if(this.$i18n.locale == 'de'){
            //         nombreRuta = ruta.nombres[3].texto;
            // }
            })
            this.rutasOptions.unshift({ value:null, text: this.$t('default.SELECT_RUTA')})
            
            this.rutasOptionsFiltered = this.rutasOptions;
        })
           
        ApiService.getAllCordinates(_language).then(result =>{
            $vm.monumentos = [...result.data]; 
            $vm.monumentos.unshift({value: null, text: this.$t('default.ALL_MONUMENTS') })

            $vm.monumentosFiltered = $vm.monumentos.map(monumento =>{  
                if('value' in monumento){
                    return {value: null, text: monumento.text}
                }else{
                    let nombreEdificio = monumento.webName;
                    return {value: monumento, text: nombreEdificio}
                    // let nombreEdificio;
                    // if(this.$i18n.locale == 'es'){
                    //     nombreEdificio = monumento.nombres[0].texto;
                    // }
                    // if(this.$i18n.locale == 'en'){
                    //     nombreEdificio = monumento.nombres[1].texto;
                    // }
                    // if(this.$i18n.locale == 'fr'){
                    //     nombreEdificio = monumento.nombres[2].texto;
                    // }
                    // if(this.$i18n.locale == 'de'){
                    //     nombreEdificio = monumento.nombres[3].texto;
                    // }
    
                    // return {value: monumento, text: nombreEdificio}
                }                
            });
        })

        $vm.comunidadesAutonomas = $vm.$store.getters.comunidadesAutonomas;

        $vm.comunidadesAutonomas.forEach( comunidad => {
            if(comunidad.value == null){
                comunidad.text = this.$t('default.SELECT_COMUNIDAD')
            }
        })
    },
    computed: {
        isCastillosSelected(){
            let $vm = this;
            if($vm.selectedType === "Castillos"){
                $vm.castillosColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.castillosDescriptionText');
                $vm.filterByTipo("Castillos");               

                return true;
            }
            else{
                $vm.castillosColor = $vm.disabledColor;
                return false;  
            } 
        },
        isPalaciosSelected(){
            let $vm = this;
            if($vm.selectedType === "Palacios"){
                $vm.palaciosColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.palaciosDescriptionText'); 
                $vm.filterByTipo("Palacios");               
                return true;
            }
            else{
                $vm.palaciosColor = $vm.disabledColor;
                return false;  
            } 
        },
        isPalaciosRealesSelected(){
            let $vm = this;
            if($vm.selectedType === "Palacios Reales"){
                $vm.palaciosRealesColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.palaciosRealesDescriptionText');
                $vm.filterByTipo("Palacios Reales");               
                return true;
            }
            else{
                $vm.palaciosRealesColor = $vm.disabledColor;
                return false;  
            } 
        },        
        isRecintosSelected(){
            let $vm = this;
            if($vm.selectedType === "Recintos Amurallados"){
                $vm.recintosColor = $vm.activeColor;
                $vm.descripciones = $vm.$t('default.recintosAmuralladosDescriptionText');
                $vm.filterByTipo("Recintos amurallados");               
                return true;
            }
            else{
                $vm.recintosColor = $vm.disabledColor;
                return false;  
            } 
        },

        isMonasteriosSelected(){
            let $vm = this;
            if($vm.selectedType === "Monasterios"){
                $vm.monasteriosColor = $vm.activeColor;
                $vm.descripciones = "";
                $vm.filterByTipo("Monasterios");               
                return true;
            }
            else{
                $vm.monasteriosColor = $vm.disabledColor;
                return false;  
            } 
        },
        isArteSacroSelected(){
            let $vm = this;
            if($vm.selectedType === "Arte Sacro"){
                $vm.arteColor = $vm.activeColor;
                $vm.descripciones = "";
                $vm.filterByTipo("Arte Sacro");               

                return true;
            }
            else{
                $vm.arteColor = $vm.disabledColor;
                return false;  
            } 
        }
    }
}
</script>

<style scoped>
    @media (max-width: 1440px){
        .visitas-main .spainhn-subtitle-2{
            font-size: 24px;
        }
    }   
    @media (max-width: 768px){
        .visitas-main .spainhn-subtitle-4{
            font-size: 18px;
        }
    }
    @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
    .disable-selector{
        color:rgba(225,225,225);
    }
</style>